import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { HomePageComponent } from './main-pages/home-page/home-page.component';
import { AboutPageComponent } from './main-pages/about-page/about-page.component';
import { AppRoutingModule } from './app-routing.module';
import { EventsPageComponent } from './main-pages/events-page/events-page.component';
import { ToursPageComponent } from './main-pages/tours-page/tours-page.component';
import { GalleryPageComponent } from './main-pages/gallery-page/gallery-page.component';
import { VideosPageComponent } from './main-pages/videos-page/videos-page.component';
import { BlogPageComponent } from './main-pages/blog-page/blog-page.component';
import { ArmeniaPageComponent } from './main-pages/armenia-page/armenia-page.component';
import { FooterComponent } from './footer/footer.component';
import { InfiniteCarouselComponent } from './infinite-carousel/infinite-carousel.component';

// pages


import { BlogPage01Component } from './pages/blog/blog-page01/blog-page01.component';
import { BlogPage02Component } from './pages/blog/blog-page02/blog-page02.component';
import { BlogPage03Component } from './pages/blog/blog-page03/blog-page03.component';
import { BlogPage04Component } from './pages/blog/blog-page04/blog-page04.component';
import { BlogPage05Component } from './pages/blog/blog-page05/blog-page05.component';
import { BlogPage06Component } from './pages/blog/blog-page06/blog-page06.component';
import { BlogPage07Component } from './pages/blog/blog-page07/blog-page07.component';
import { ArmeniaPage01Component } from './pages/armenia/armenia-page01/armenia-page01.component';
import { ArmeniaPage02Component } from './pages/armenia/armenia-page02/armenia-page02.component';
import { ArmeniaPage03Component } from './pages/armenia/armenia-page03/armenia-page03.component';
import { ArmeniaPage04Component } from './pages/armenia/armenia-page04/armenia-page04.component';
import { ArmeniaPage05Component } from './pages/armenia/armenia-page05/armenia-page05.component';
import { ArmeniaPage06Component } from './pages/armenia/armenia-page06/armenia-page06.component';
import { ArmeniaPage07Component } from './pages/armenia/armenia-page07/armenia-page07.component';
import { ArmeniaPage08Component } from './pages/armenia/armenia-page08/armenia-page08.component';
import { ArmeniaPage09Component } from './pages/armenia/armenia-page09/armenia-page09.component';
import { ArmeniaPage10Component } from './pages/armenia/armenia-page10/armenia-page10.component';
import { ArmeniaPage11Component } from './pages/armenia/armenia-page11/armenia-page11.component';
import { ArmeniaPage12Component } from './pages/armenia/armenia-page12/armenia-page12.component';
import { ToursPage01Component } from './pages/tours/tours-page01/tours-page01.component';
import { ToursPage02Component } from './pages/tours/tours-page02/tours-page02.component';
import { ToursPage03Component } from './pages/tours/tours-page03/tours-page03.component';
import { ToursPage04Component } from './pages/tours/tours-page04/tours-page04.component';
import { ToursPage05Component } from './pages/tours/tours-page05/tours-page05.component';
import { ToursPage06Component } from './pages/tours/tours-page06/tours-page06.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutPageComponent,
    EventsPageComponent,
    ToursPageComponent,
    GalleryPageComponent,
    VideosPageComponent,
    BlogPageComponent,
    ArmeniaPageComponent,
    FooterComponent,
    InfiniteCarouselComponent,
    BlogPage01Component,
    BlogPage02Component,
    BlogPage03Component,
    BlogPage04Component,
    BlogPage05Component,
    BlogPage06Component,
    BlogPage07Component,
    ArmeniaPage01Component,
    ArmeniaPage02Component,
    ArmeniaPage03Component,
    ArmeniaPage04Component,
    ArmeniaPage05Component,
    ArmeniaPage06Component,
    ArmeniaPage07Component,
    ArmeniaPage08Component,
    ArmeniaPage09Component,
    ArmeniaPage10Component,
    ArmeniaPage11Component,
    ArmeniaPage12Component,
    ToursPage01Component,
    ToursPage02Component,
    ToursPage03Component,
    ToursPage04Component,
    ToursPage05Component,
    ToursPage06Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
