import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  menuOpened = true;

  @HostListener('window:scroll', [])
onWindowScroll() {
  return document.body.scrollTop > 1 || document.documentElement.scrollTop > 1 ? true : false;
}

openMainMenu() {
  this.menuOpened = !this.menuOpened;
}

menuShow() {
  return this.menuOpened;
}

// this.defaulValue = this.defaulValue + 1;
// return this.defaulValue % 2 === 0 ? true : false;

}
