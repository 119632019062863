import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-armenia-page07',
  templateUrl: './armenia-page07.component.html',
  styleUrls: ['./armenia-page07.component.css']
})
export class ArmeniaPage07Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
