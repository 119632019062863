import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-armenia-page02',
  templateUrl: './armenia-page02.component.html',
  styleUrls: ['./armenia-page02.component.css']
})
export class ArmeniaPage02Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
