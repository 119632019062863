import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-armenia-page06',
  templateUrl: './armenia-page06.component.html',
  styleUrls: ['./armenia-page06.component.css']
})
export class ArmeniaPage06Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
