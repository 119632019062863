import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-page06',
  templateUrl: './blog-page06.component.html',
  styleUrls: ['./blog-page06.component.css']
})
export class BlogPage06Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
