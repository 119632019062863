import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-page02',
  templateUrl: './blog-page02.component.html',
  styleUrls: ['./blog-page02.component.css']
})
export class BlogPage02Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
