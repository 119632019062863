import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tours-page05',
  templateUrl: './tours-page05.component.html',
  styleUrls: ['./tours-page05.component.css']
})
export class ToursPage05Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
