import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './main-pages/home-page/home-page.component';
import { AboutPageComponent } from './main-pages/about-page/about-page.component';
import { EventsPageComponent } from './main-pages/events-page/events-page.component';
import { ToursPageComponent } from './main-pages/tours-page/tours-page.component';
import { GalleryPageComponent } from './main-pages/gallery-page/gallery-page.component';
import { VideosPageComponent } from './main-pages/videos-page/videos-page.component';
import { BlogPageComponent } from './main-pages/blog-page/blog-page.component';
import { ArmeniaPageComponent } from './main-pages/armenia-page/armenia-page.component';

// pages

import { BlogPage01Component } from './pages/blog/blog-page01/blog-page01.component';
import { BlogPage02Component } from './pages/blog/blog-page02/blog-page02.component';
import { BlogPage03Component } from './pages/blog/blog-page03/blog-page03.component';
import { BlogPage04Component } from './pages/blog/blog-page04/blog-page04.component';
import { BlogPage05Component } from './pages/blog/blog-page05/blog-page05.component';
import { BlogPage06Component } from './pages/blog/blog-page06/blog-page06.component';
import { BlogPage07Component } from './pages/blog/blog-page07/blog-page07.component';
import { ArmeniaPage01Component } from './pages/armenia/armenia-page01/armenia-page01.component';
import { ArmeniaPage02Component } from './pages/armenia/armenia-page02/armenia-page02.component';
import { ArmeniaPage03Component } from './pages/armenia/armenia-page03/armenia-page03.component';
import { ArmeniaPage04Component } from './pages/armenia/armenia-page04/armenia-page04.component';
import { ArmeniaPage05Component } from './pages/armenia/armenia-page05/armenia-page05.component';
import { ArmeniaPage06Component } from './pages/armenia/armenia-page06/armenia-page06.component';
import { ArmeniaPage07Component } from './pages/armenia/armenia-page07/armenia-page07.component';
import { ArmeniaPage08Component } from './pages/armenia/armenia-page08/armenia-page08.component';
import { ArmeniaPage09Component } from './pages/armenia/armenia-page09/armenia-page09.component';
import { ArmeniaPage10Component } from './pages/armenia/armenia-page10/armenia-page10.component';
import { ArmeniaPage11Component } from './pages/armenia/armenia-page11/armenia-page11.component';
import { ArmeniaPage12Component } from './pages/armenia/armenia-page12/armenia-page12.component';
import { ToursPage01Component } from './pages/tours/tours-page01/tours-page01.component';
import { ToursPage02Component } from './pages/tours/tours-page02/tours-page02.component';
import { ToursPage03Component } from './pages/tours/tours-page03/tours-page03.component';
import { ToursPage04Component } from './pages/tours/tours-page04/tours-page04.component';
import { ToursPage05Component } from './pages/tours/tours-page05/tours-page05.component';

const appRoutes: Routes = [
  { path: 'about', component: AboutPageComponent },
  { path: '', component: HomePageComponent },
  { path: 'events', component: EventsPageComponent },
  { path: 'tours', component: ToursPageComponent },
  { path: 'gallery', component: GalleryPageComponent },
  { path: 'videos', component: VideosPageComponent },
  { path: 'blog', component: BlogPageComponent },
  { path: 'armenia', component: ArmeniaPageComponent },
  { path: 'blog/1', component: BlogPage01Component },
  { path: 'blog/2', component: BlogPage02Component },
  { path: 'blog/3', component: BlogPage03Component },
  { path: 'blog/4', component: BlogPage04Component },
  { path: 'blog/5', component: BlogPage05Component },
  { path: 'blog/6', component: BlogPage06Component },
  { path: 'blog/7', component: BlogPage07Component },
  { path: 'armenia', component: ArmeniaPageComponent },
  { path: 'armenia/1', component: ArmeniaPage01Component },
  { path: 'armenia/2', component: ArmeniaPage02Component },
  { path: 'armenia/3', component: ArmeniaPage03Component },
  { path: 'armenia/4', component: ArmeniaPage04Component },
  { path: 'armenia/5', component: ArmeniaPage05Component },
  { path: 'armenia/6', component: ArmeniaPage06Component },
  { path: 'armenia/7', component: ArmeniaPage07Component },
  { path: 'armenia/8', component: ArmeniaPage08Component },
  { path: 'armenia/9', component: ArmeniaPage09Component },
  { path: 'armenia/10', component: ArmeniaPage10Component },
  { path: 'armenia/11', component: ArmeniaPage11Component },
  { path: 'armenia/12', component: ArmeniaPage12Component },
  { path: 'tours/01', component: ToursPage01Component },
  { path: 'tours/02', component: ToursPage02Component },
  { path: 'tours/03', component: ToursPage03Component },
  { path: 'tours/04', component: ToursPage04Component },
  { path: 'tours/05', component: ToursPage05Component }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
