import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tours-page04',
  templateUrl: './tours-page04.component.html',
  styleUrls: ['./tours-page04.component.css']
})
export class ToursPage04Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
