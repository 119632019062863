import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-armenia-page12',
  templateUrl: './armenia-page12.component.html',
  styleUrls: ['./armenia-page12.component.css']
})
export class ArmeniaPage12Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
