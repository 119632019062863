import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-page01',
  templateUrl: './blog-page01.component.html',
  styleUrls: ['./blog-page01.component.css']
})
export class BlogPage01Component implements OnInit {

  id: string;
  header: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.header = this.route.snapshot.params['header'];
  }
}
